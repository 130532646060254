import React from 'react';
import Hero from "../hero/hero";
import Health from "../health/health";
import Choose from "../choose/choose";
import Program from "../program/program";
import Coache from "../coaches/coache";
import Contact from "../contact/contact";

const Main = () => {
    return (
        <div>
            <Hero />
            <Health />
            <Choose />
            <Program />
            <Coache />
            <Contact />
        </div>
    );
}

export default Main;
