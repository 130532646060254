import React from 'react';
import "./choose.css";
import oneChoose from "../../assests/gursimrat-ganda-TT042bOytlU-unsplash.jpg";
import towChoose from "../../assests/omid-armin-rAh9rA53UWk-unsplash.jpg";
import threeChoose from "../../assests/fortune-vieyra-jD4MtXnsJ6w-unsplash.jpg";
const Choose = () => {
    return (
        <div>
            <div id='choose' className="why-us  overflow-hidden py-5 my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h1 className="d-inline-block d-lg-none head-choose fw-light text-white">WHY <span className="choouse-us d-inline-block py-3 px-1">CHOOSE US?</span> </h1>
                            <div className="row bg-white">
                                <div className="col-6">
                                    <div className="w-100 h-100 bg-info" style={{ maxHeight: "500px" }}>
                                        <img className="w-100 h-100" src={oneChoose} alt="" style={{ objectFit: "cover", objectPosition: "right" }} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="w-100 bg-info" style={{ maxHeight: "250px" }}>
                                        <img className="w-100 h-100 img-fluid" src={towChoose} alt="" />
                                    </div>
                                    <div className="w-100 bg-info overflow-hidden" style={{ maxHeight: "250px" }}>
                                        <img className="w-100 h-100 img-fluid" src={threeChoose} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="px-5">
                                <h1 className="d-none d-lg-inline-block head-choose fw-light text-white">WHY <span className="choouse-us d-inline-block py-3 px-1">CHOOSE US?</span> </h1>
                                <ul className="list-unstyled mt-4">
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">OVER 140+ EXPERT COACHS</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">OVER 60+ Programs For Everyone</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">Large Number Of Members</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">Monthly And Yearly Offers</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">Group Exercises</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">RELIABLE PARTNERS</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">Providing Physical Counseling</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">Follow Up From Coaches</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">Healthy Diet</label>
                                    </li>
                                    <li className="text-white d-flex justify-content-start align-items-center">
                                        <span className="choouse-check d-inline-block pe-2 fs-4"><i className="fa-solid fa-check"></i></span>
                                        <label className="fw-light">And More</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Choose;
